import { Participants } from '@/constants/participantsType'

export const calculatePrice = (
  experience,
  totalParticipants,
  totalParticipantQuantity,
  setAdditionalAmount,
  totalProducts,
  setRangeDiscount,
  isPackage = false
) => {
  setAdditionalAmount(0)
  let localAdditionalAmount = 0
  let totalPrice = 0
  let discountPercentage:number = 0;

  if (!isPackage) {
    const getPrice = (priceKey) =>
      (experience?.[`price_${priceKey}`] ?? 0) *
      (totalParticipants[priceKey] ?? 0)

    const priceKeys = [
      Participants.ADULTS,
      Participants.CHILDREN,
      Participants.BABIES,
      Participants.SENIORS,
    ]

    if (experience?.price_discount_groups?.length > 0) {
      discountPercentage = experience?.price_discount_groups?.find(
        (group) =>
          totalParticipantQuantity >= group?.min_participants &&
          totalParticipantQuantity <= group?.max_participants
      )?.discount;
      
      if (discountPercentage > 0) {
        setRangeDiscount(discountPercentage);
      } else {
        setRangeDiscount(null);
      }
    }

      totalPrice = priceKeys.reduce(
      (acc, priceKey) => {
        const originalPrice = getPrice(priceKey);
        const discountedPrice = originalPrice - (discountPercentage > 0 ? (originalPrice * (discountPercentage / 100)) : 0);
        return acc + discountedPrice;
      },
      0
    );

    
  } else {
    totalPrice = experience?.price_package
  }

  totalProducts?.map((additionalProduct) => {
    localAdditionalAmount =
      (localAdditionalAmount ?? 0) +
      (additionalProduct?.price *
        (additionalProduct.apply_to === 2 ? totalParticipantQuantity : 1) ?? 0)

    setAdditionalAmount(localAdditionalAmount)
  })

  return totalPrice
}
