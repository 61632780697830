import {
  AvatarPersonIcon,
  ClockIcon,
  DifficultyIcon,
  LangIcon,
  MapPinIcon,
  MaxPersonIcon,
  MinPersonIcon,
  PersonIcon,
  ShieldIcon,
} from '@/assets/icons'
import {
  EXPERIENCE_DIFFICULTY,
  EXPERIENCE_LANG,
  EXPERIENCE_TYPE,
} from '@/constants/experience'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { Divider } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

const ExperienceDetails = () => {
  const { t } = useTranslation()

  const {
    state: { experience },
  } = useGotuuriContext()

  const DetailBullet = ({
    title,
    text,
    icon,
  }: {
    title: string | JSX.Element
    text: string | JSX.Element
    icon: JSX.Element
  }) => {
    return (
      <li className={`flex mb-3 md:mb-6`}>
        <div className="flex items-center justify-center w-7 mr-0.5">
          {icon}
        </div>
        <div className="flex flex-col flex-grow w-full">
          <div className="flex flex-wrap items-center">
            <h4 className="text-lg whitespace-pre-wrap break-words mr-2">
              {title}
            </h4>
            <p className="text-darkGray">{text}</p>
          </div>
        </div>
      </li>
    )
  }
  const iconClass = 'w-[18px] h-[18px]'
  return (
    <>
      <Divider className="bg-liteGray my-6" />
      <div className="flex flex-col md:flex-row">
        <h3 className="text-2xl w-full md:w-60 mb-4 md:mb-0 h-min">
          {t('experience.detailsTitle')}
        </h3>
        <section id="details">
          <ul className="w-full">
            <DetailBullet
              title={t('experience.details.type')}
              text={t(
                EXPERIENCE_TYPE[experience?.is_private ? 'true' : 'false']
              )}
              icon={<ShieldIcon className={iconClass} />}
            />
            <DetailBullet
              title={t('experience.details.duration')}
              text={t('experience.details.durationData', {
                count: parseInt(experience?.duration?.toString() || '0'),
              })}
              icon={<ClockIcon className={iconClass} />}
            />
            <DetailBullet
              title={t('experience.details.lang')}
              text={experience?.lang_codes
                ?.map((item) =>
                  t(EXPERIENCE_LANG[item as keyof typeof EXPERIENCE_LANG])
                )
                ?.join(', ')}
              icon={<LangIcon className={iconClass} />}
            />
            <DetailBullet
              title={t('experience.details.difficulty')}
              text={t(
                EXPERIENCE_DIFFICULTY[
                  experience?.difficulty_level as keyof typeof EXPERIENCE_DIFFICULTY
                ]
              )}
              icon={<DifficultyIcon className={iconClass} />}
            />
            <DetailBullet
              title={t('experience.details.minParticipants')}
              text={experience?.min_persons?.toString()}
              icon={<MinPersonIcon className="w-[22px] -ml-0.5" />}
            />
            <DetailBullet
              title={t('experience.details.maxParticipants')}
              text={experience?.max_persons?.toString()}
              icon={<MaxPersonIcon className={iconClass} />}
            />
            {!!experience?.min_age_allowed && (
              <DetailBullet
                title={t('experience.details.minAgeAllowed')}
                text={`${experience?.min_age_allowed?.toString()} ${t(
                  'experience.details.years'
                )}`}
                icon={<PersonIcon className={iconClass} />}
              />
            )}
            {!!experience?.meeting_reference && (
              <DetailBullet
                title={t('experience.details.meetingPoint')}
                text={
                  <a
                    className="underline underline-offset-2 hover:text-primary"
                    href={`https://www.google.com/maps?q=${experience?.meeting_latitude},${experience?.meeting_longitude}`}
                  >
                    {experience?.meeting_reference}
                  </a>
                }
                icon={<MapPinIcon className={iconClass} />}
              />
            )}
            <DetailBullet
              title="Operado por:"
              text={experience?.companies?.[0]?.owner?.first_name ?? ''}
              icon={<AvatarPersonIcon className={iconClass} />}
            />
          </ul>
        </section>
      </div>
    </>
  )
}

export default ExperienceDetails
