import { Calendar } from 'react-date-range'
import { addDays, getDay } from 'date-fns'
import { dateFnsLanguage, useFormatDate } from 'utils/formatDate.js'
import { useState, useEffect } from 'react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import './calendar.css'
import { Accordion } from 'components/Accordion'
import { Button, Divider } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { googleTrack, track } from '@/utils/analytics'
import { GOTUURI_CYAN } from '@/constants/common'
import { CalendarIcon } from '@/assets/icons'

interface CalendarRowProps {
  handleBookingDate: (data) => void
  isDateRange?: boolean
  rangeMinDate?: string
  rangeMaxDate?: string
}

const CalendarRow = ({
  handleBookingDate,
  isDateRange = false,
  rangeMinDate = null,
  rangeMaxDate = null,
}: CalendarRowProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [bookingDate, setBookingDate] = useState(null)
  const {
    hooks: { postAvailableHour },
    state: { companySchedule, experience, bookingData, disableSchedules },
  } = useGotuuriContext()

  const setGlobalBookingDate = (date) => {
    googleTrack('ExperienceDateSelect')
    handleBookingDate(date)
  }

  const handleSelectDate = (item) => {
    const body = {
      company:
        experience?.companies[0].id ?? bookingData?.experience?.companies[0].id,
      experience: experience?.id ?? bookingData?.experience?.id,
      date_booking: useFormatDate(item, 'yyyy-MM-dd'),
    }
    setBookingDate(new Date(item))
    postAvailableHour(body)
    setGlobalBookingDate(item)
  }

  useEffect(() => {
    if (
      !!bookingData &&
      bookingData?.experience?.id === id &&
      !!disableSchedules
    ) {
      handleSelectDate(bookingData?.bookingDate ?? null)
      setGlobalBookingDate(bookingData?.bookingDate ?? null)
    }
  }, [bookingData, disableSchedules])

  // Determinar si el día de la semana debe ser deshabilitado
  const isDayDisabled = (date) => {
    const dayOfWeek = getDay(date)
    const weekdays = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    if (
      !!companySchedule &&
      Array.isArray(companySchedule.all_days) &&
      !companySchedule.all_days.length
    ) {
      const dayKey = weekdays[dayOfWeek]
      return companySchedule[dayKey].length === 0
    }
    return false
  }

  const minDate =
    new Date() > new Date(rangeMinDate) ? new Date() : new Date(rangeMinDate)
  const minAddDate = addDays(
    new Date(minDate.toDateString()),
    companySchedule?.anticipationDay || 0
  )
  const maxDate =
    new Date() > new Date(rangeMaxDate) ? new Date() : new Date(rangeMaxDate)

  const calendarProps = isDateRange
    ? {
        minDate: minAddDate,
        maxDate: maxDate,
        disabledDates: disableSchedules?.disabledDates,
        disabledDay: isDayDisabled,
      }
    : {
        disabledDay: isDayDisabled,
        minDate: addDays(new Date(), companySchedule?.anticipationDay || 0),
        disabledDates: disableSchedules?.disabledDates,
      }

  return (
    <>
      <Accordion
        header={(isOpen) => (
          <div
            className="flex flex-row w-full select-none"
            role="button"
            onClick={() => track('Experience Calendar View')}
          >
            <CalendarIcon className="h-5 w-5 mr-4 my-auto" />
            <h3 className="text-base text-black text-center truncate">
              {bookingDate
                ? useFormatDate(bookingDate, 'dd/MM/yyyy')
                : t('bookingCard.selectDate')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        {(close) =>
          isDateRange &&
          (String(minDate) == String(maxDate) || minAddDate > maxDate) ? (
            <div className="max-h-48 mt-2 overflow-y-auto p-px scrollbar-hide overscroll-none">
              <Button
                isDisabled
                className="shadow rounded-2xl cursor-pointer h-12 text p-3 mb-2 w-full justify-start"
              >
                {t('uniqueDatesRow.noSchedules')}
              </Button>
            </div>
          ) : (
            <Calendar
              {...calendarProps}
              color={GOTUURI_CYAN}
              locale={dateFnsLanguage()}
              onChange={(item) => {
                track('Experience Date Select')
                googleTrack('ExperienceDateSelect')
                setBookingDate(item)
                handleSelectDate(item)
                close()
              }}
              date={bookingDate}
            />
          )
        }
      </Accordion>
      <Divider className="mb-8 mt-2" />
    </>
  )
}
export default CalendarRow
