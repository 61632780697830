export const URL =
  'https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/data_web%2Fcontact%20json%2Fcontact.json?alt=media&token=e3e57364-5274-48ec-b175-f55131d89c46'
export const WHATSAPP = 'https://wa.me/+50768557651'
export const EMAIL = 'mailto:help@gotuuri.com'
export const WHATSAPP_LINK = 'https://wa.me/'
export const EMAIL_LINK = 'mailto:'

export const APPSTORE = 'https://apps.apple.com/us/app/gotuuri/id6443990852'
export const GOOGLEPLAY =
  'https://play.google.com/store/apps/details?id=com.ap.gotuuri&pli=1'
