import { Button } from '@nextui-org/react'
import { Rating } from '../Rating'
import defaultAvatar from 'assets/svgs/defaultAvatar.svg'
import { useFormatDate } from '@/utils/formatDate'
import { Ref, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
interface ExperienceReviewCardProps {
  experienceReview: Record<string, any>[]
}
const ExperienceReviewCard = ({
  experienceReview,
}: ExperienceReviewCardProps) => {
  const { t } = useTranslation()
  const [reviewsToShow, setReviewsToShow] = useState(
    experienceReview.length < 3 ? experienceReview.length : 3
  )

  const handleReviewsToShow = () => {
    setReviewsToShow(reviewsToShow + 3)
  }
  function handleViewLessReviews(): void {
    setReviewsToShow(3)
    scrollToSection('reviews')
  }

  const scrollToSection = (section: string) => {
    const element = document.getElementById(section)
    if (element) {
      const yOffset = -100
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  return (
    <>
      {!!experienceReview.length &&
        experienceReview.slice(0, reviewsToShow).map((item, index) => {
          return (
            <div key={index} className="mb-7">
              <div className="flex flex-row h-14 ">
                <img
                  className="w-14 h-14 rounded-full"
                  src={item?.customer?.avatar_url || defaultAvatar}
                  alt={item?.customer?.first_name}
                />
                <div className="flex-col ml-4 overflow-hidden">
                  <h3 className="text-xl truncate">
                    {item.customer?.first_name} {item.customer?.last_name}
                  </h3>
                  <Rating
                    initialRating={item?.rating || 5}
                    size={'text-xl'}
                    disabled={true}
                  />
                </div>
                <p className="ml-auto mt-1 text-gray-400">
                  {useFormatDate(item?.date)}
                </p>
              </div>
              <p className="text-darkGray text-sm mt-2 text-ellipsis overflow-hidden">
                {item?.comments}
              </p>
            </div>
          )
        })}
      {experienceReview.length > reviewsToShow ? (
        <Button
          className="mt-auto rounded-full border border-black py-px "
          onClick={handleReviewsToShow}
        >
          {t('experience.viewMoreReviews')}
        </Button>
      ) : experienceReview.length > 3 ? (
        <Button
          className="mt-auto rounded-full border border-black py-px "
          onClick={handleViewLessReviews}
        >
          {t('experience.viewLessReviews')}
        </Button>
      ) : null}
    </>
  )
}
export default ExperienceReviewCard
