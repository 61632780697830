const DetailSimpleBullet = ({
  name,
  icon = '•',
  className = '',
  textClass = 'line-clamp-2 w-full',
}: {
  name: string | JSX.Element
  icon?: string | JSX.Element
  className?: string
  textClass?: string
}) => {
  return (
    <li className={`flex mb-3 md:mb-6 ${className}`}>
      <div className="flex justify-center !w-7 mr-0.5">{icon}</div>
      <p className={textClass}>{name}</p>
    </li>
  )
}

export default DetailSimpleBullet
