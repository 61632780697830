import { Disclosure, Transition } from '@headlessui/react'
import { ReactNode } from 'react'

interface AccordionProps {
  header: ((isOpen: boolean) => ReactNode) | ReactNode
  children: ((isOpen: () => void) => ReactNode) | ReactNode
  disabled?: boolean
}

const Accordion = ({ header, children, disabled = false }: AccordionProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button disabled={disabled} className={'w-full'}>
            {typeof header === 'function' ? header(open) : header}
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-98 opacity-0"
            enterTo="transform scale-100 opacity-100"
          >
            <Disclosure.Panel>
              {({ close }) => (
                <>
                  {typeof children === 'function' ? children(close) : children}
                </>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default Accordion
