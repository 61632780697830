import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/icons'
import { CategoryInterface } from '@/types/category'
import { normalizeItem } from '@/utils/normalizeUrl'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../Loading'
import { useListenLocalStorage } from '@/hooks/useListenLocalStorage'
import { COUNTRY_ID } from '@/constants/cookie'

const CarouselHighlights = ({
  data,
  isMobile,
  className,
  eventTrigger,
}: {
  data: CategoryInterface[]
  isMobile?: boolean
  className?: string
  eventTrigger?: (item) => void
}) => {
  const { countryId } = useListenLocalStorage([COUNTRY_ID])
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      breakpoints: {
        '(min-width: 1px)': {
          slides: { perView: 1, spacing: 2 },
        },
        '(min-width: 768px)': {
          slides: { perView: 2, spacing: 3 },
        },
      },
      slides: { perView: isMobile ? 1 : 2, spacing: 3 },
      initial: 0,
      created() {
        setLoaded(true)
      },
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 4000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]
  )

  useEffect(() => {
    if (!loaded && !data?.length) return
    instanceRef.current?.update()
  }, [loaded, data])

  return (
    <div className={`navigation-wrapper ${className} relative`}>
      <div ref={sliderRef} className="keen-slider">
        {!!data?.length ? (
          data?.map((item, index) => (
            <Link
              key={index}
              className="keen-slider__slide min-w-fit max-w-fit"
              to={normalizeItem({ ...item, type: 'highlights', countryId })}
              onClick={() => {
                eventTrigger(item)
                window.scrollTo(0, 0)
              }}
            >
              <div key={index} className="h-64 md:h-80 cursor-pointer ">
                <img
                  className={`object-cover w-full h-full rounded-2xl p-2`}
                  src={item?.image}
                  alt={item?.name}
                />
                <div className="absolute bottom-8 left-8 right-4 px">
                  <h2 className="text-white text-2xl select-none">
                    {item?.name}
                  </h2>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="h-64 md:h-80 w-full">
            <Loading fullHeight={false} />
          </div>
        )}
      </div>
      <ArrowLeftIcon
        onClick={(e: any) => {
          e.stopPropagation() ||
            (instanceRef?.current && instanceRef.current?.prev())
        }}
        className="h-12 w-12 absolute -left-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
      />
      <ArrowRightIcon
        onClick={(e: any) =>
          e.stopPropagation() ||
          (instanceRef?.current && instanceRef.current?.next())
        }
        className="h-12 w-12 absolute -right-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
      />
    </div>
  )
}

export default memo(CarouselHighlights)
