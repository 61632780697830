import { Button } from '@nextui-org/react'

interface BookingMobileFooter {
  priceLabel?: string
  price?: string
  onPressAction?: () => void
  buttonLabel?: string
  isLoading?: boolean
  reverse?: boolean
  isValid?: boolean
}

const BookingMobileFooter = ({
  priceLabel = '',
  price = '$0',
  onPressAction = () => {},
  buttonLabel = '',
  isLoading = false,
  reverse = false,
  isValid = false,
}: BookingMobileFooter) => {
  return (
    <section
      className={`flex flex-row h-24 bg-white ${reverse ? 'sticky' : 'fixed'}  w-full bottom-0 inset-x-0 items-center justify-between z-30`}
    >
      <div
        className={`flex ml-8 text-black ${reverse ? 'flex-col-reverse' : 'flex-col'}`}
      >
        <p className=" text-xs">{priceLabel}</p>
        <h2 className="text-3xl">{price}</h2>
      </div>
      <Button
        type="submit"
        isLoading={isLoading}
        disabled={isLoading || !isValid}
        onPress={onPressAction}
        className="!bg-gotuuriCyan text-white rounded-3xl text-base px-6 mr-4 h-12"
      >
        {buttonLabel}
      </Button>
    </section>
  )
}

export default BookingMobileFooter
