import { useState } from 'react'
import countryRegionData from 'country-region-data/dist/data-umd'
import { Select, SelectItem } from '@nextui-org/select'
import { PANAMA_CODE } from '@/constants/common'
import { useTranslation } from 'react-i18next'
interface CountryRegionSelectItemProps {
  clearErrors: any
  setValue: any
  register: any
  defaultCountry?: string
  defaultCity?: string
  height?: string
}
const CountryRegionSelect = ({
  clearErrors,
  setValue,
  register,
  defaultCountry = PANAMA_CODE,
  defaultCity,
  height = 'h-14',
}: CountryRegionSelectItemProps) => {
  const [countryRegions, setCountryRegions] = useState(
    countryRegionData.find((data) => data?.countryShortCode === defaultCountry)
      ?.regions
  )
  const { t } = useTranslation()

  const selectsStyles = {
    label: 'hidden',
    base: `bg-mediumGray rounded-2xl !opacity-100 select-none ${height} justify-center border-1`,
    mainWrapper: 'bg-mediumGray hover:bg-mediumGray',
    trigger: 'shadow-none data-[hover=true]:bg-mediumGray bg-mediumGray',
    innerWrapper: `text-black`,
  }

  return (
    <div className="flex gap-4 justify-between">
      <Select
        {...register('country', { required: t('payment.required') })}
        label="Select"
        classNames={{
          ...selectsStyles,
          base: `bg-mediumGray rounded-2xl !opacity-100 select-none ${height} justify-center border-1 !my-0`,
        }}
        items={countryRegionData}
        className="w-2/4"
        defaultSelectedKeys={[defaultCountry]}
        labelPlacement="outside"
        disallowEmptySelection
        onChange={(selectedCountry) => {
          const selectedCountryData = countryRegionData.find(
            (data) => data?.countryShortCode === selectedCountry.target.value
          )
          setValue('country', selectedCountry.target.value)
          setValue('city', null)
          setCountryRegions(selectedCountryData?.regions || [])
        }}
      >
        {(country: any) => (
          <SelectItem
            key={country.countryShortCode}
            value={country.countryShortCode}
          >
            {country.countryName}
          </SelectItem>
        )}
      </Select>

      <Select
        // {...register('city', { required: t('payment.required') })}
        label="Select"
        items={countryRegions}
        classNames={{
          ...selectsStyles,
          innerWrapper: 'overflow-hidden',
          base: `bg-mediumGray rounded-2xl !opacity-100 select-none ${height} justify-center border-1 !my-0 !overflow-hidden`,
          value: 'tracking-tight overflow-hidden',
        }}
        labelPlacement="outside"
        placeholder={t('payment.city')}
        defaultSelectedKeys={[defaultCity ?? '']}
        disallowEmptySelection
        onChange={(selectedRegion) => {
          setValue('city', selectedRegion?.target.value)
          clearErrors('city')
        }}
        className="!w-2/4"
      >
        {(region: any) => (
          <SelectItem key={region?.name} value={region?.shortCode}>
            {region?.name}
          </SelectItem>
        )}
      </Select>
    </div>
  )
}

export default CountryRegionSelect
