import { Link } from 'react-router-dom'
import { Button } from '@nextui-org/react'

interface IconButtomProps {
  textButtom: string
  linkUrl: string
  target?: string
  srcImg?: string | null
  altImg: string
  className?: string,
  onClickFunction?: () => void
}

const IconButtom = ({
  textButtom,
  linkUrl,
  srcImg,
  altImg,
  target = '_self',
  className,
  onClickFunction,
}: IconButtomProps) => {
  return (
    <Link to={linkUrl} target={target}>
      <Button
        onClick={() => onClickFunction()}
        className={`!bg-white text-gotuuriCyan rounded-full gap-0 ps-1 pe-2 pt-0 h-6 text-[13px] shadow-md ${className}`}
      >
        { srcImg && <img className="h-4 w-4" src={srcImg} alt={altImg} />}
        
        {textButtom}
      </Button>
    </Link>
  )
}

export default IconButtom
