import forwardIcon from 'assets/svgs/forwardIcon.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ReadMoreProps {
  text: string
}
const ReadMore = ({ text = '' }: ReadMoreProps) => {
  const { t } = useTranslation()
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <div id="description" className="relative">
      <h3 className="block md:hidden text-2xl w-60 mb-4 md:mb-0">
        {t('experience.description')}
      </h3>
      <div
        className={`absolute top-0 left-0 w-full h-full ${
          isReadMore ? 'bg-gradient-to-t from-white via-white to-35%' : 'hidden'
        } `}
      ></div>
      <div
        className={`${
          isReadMore ? 'h-fit max-h-48 ' : 'h-auto mb-2'
        } !text-darkGray [&>p>font]:!text-darkGray [&>*]:!text-darkGray [&>p>font]:!text-lg [&>*]:!text-lg text-lg overflow-hidden space-y-3`}
        dangerouslySetInnerHTML={{ __html: text.trim() }}
      ></div>
      <span
        onClick={toggleReadMore}
        className="flex flex-row items-center  relative cursor-pointer text-black font-medium w-fit"
      >
        {isReadMore ? t('experience.showMore') : t('experience.showLess')}
        <img
          className={`ml-2 h-4 w-4 transition-all ${isReadMore ? 'rotate-90' : 'rotate-270'}`}
          src={forwardIcon}
          alt="Forward icon svg"
        />
      </span>
    </div>
  )
}

export default ReadMore
