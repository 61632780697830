import search from 'assets/svgs/search.svg'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from '@nextui-org/react'
import { FilterButton } from '../FilterButton'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { normalizeFilter } from '@/utils/normalizeUrl'
import { useEffect } from 'react'
import { track } from '@/utils/analytics'
import { COUNTRY_ID } from '@/constants/cookie'
interface SearchBarProps {
  showSubmitButton?: boolean
  showFilterIcon?: boolean
  showFilterText?: boolean
  callBack: ({ filterUrl }) => void
  pathType?: string
  eventScreen?: string
}
const SearchBar = ({
  showSubmitButton = false,
  showFilterIcon,
  showFilterText,
  callBack,
  pathType,
  eventScreen,
}: SearchBarProps) => {
  const {
    hooks: { setFilterData },
    state: { filterData },
  } = useGotuuriContext()
  const { t } = useTranslation()

  const { register, handleSubmit } = useForm({
    values: { searchText: filterData?.urlParams?.searchText ?? '' },
    mode: 'onSubmit',
  })

  const onSubmit: SubmitHandler<{ searchText: string }> = (data) => {
    const newFilterData = {
      ...filterData,
      urlParams: {
        ...filterData?.urlParams,
        searchText: data.searchText,
        country: localStorage.getItem(COUNTRY_ID),
      },
    }
    setFilterData(newFilterData)
    const filterUrl = normalizeFilter(newFilterData.urlParams)
    callBack({ filterUrl })
  }
  return (
    <div
      role="search"
      className="flex items-center gap-2 md:gap-4 h-14 w-full px-0"
    >
      <form
        className="flex bg-white rounded-full py-1 px-2 md:px-3 shadow-md text-black w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <img
          className="h-6 md:h-7 ml-2 my-auto"
          src={search}
          alt="search outline svg"
          width="28"
          height="28"
        />
        <input
          {...register('searchText')}
          type="text"
          maxLength={35}
          className="w-full focus:outline-none py-2.5 px-4 text-sm md:text-xl"
          placeholder={t('searchBar.lookingFor')}
        />
        {showSubmitButton && (
          <Button
            className="!bg-gotuuriCyan my-0.5 md:my-1 rounded-full w-40 text-white text-lg"
            type="submit"
          >
            {t('searchBar.search')}
          </Button>
        )}
      </form>
      {showFilterIcon ||
        (showFilterText && (
          <div
            className={'min-w-fit'}
            onClick={() => {
              !!eventScreen
                ? track('Filter View', { screen: eventScreen })
                : null
            }}
          >
            {showFilterIcon && (
              <FilterButton iconOnly={true} pathType={pathType} />
            )}
            {showFilterText && (
              <FilterButton iconOnly={false} pathType={pathType} />
            )}
          </div>
        ))}
    </div>
  )
}
export default SearchBar
