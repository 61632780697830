import React, { useEffect, useMemo, useState } from 'react'
import ArrowDown from 'assets/svgs/arrowDown.svg'
import DefaultAvatar from 'assets/svgs/defaultAvatar.svg'
import { useIsMobile } from '@/utils/mobile'
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ENGLISH,
  SPANISH,
  WHITE,
  BLACK,
  LINK_APP,
  NAVBAR_ROUTES_COLORS,
} from '@/constants/common'
import { SignInUp } from 'components/SignInUp'
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Divider,
  Avatar,
  DropdownSection,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Select,
  SelectItem,
  useDisclosure,
} from '@nextui-org/react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useListenLocalStorage } from '@/hooks/useListenLocalStorage'
import { setLoggedInAction, setUserDataAction } from '@/store/actions'
import { track } from '@/utils/analytics'
import LogoBlue from '@/assets/svgs/logo-blue.svg'
import LogoWhite from '@/assets/images/logo-white.webp'
import LogoSmallBlue from '@/assets/svgs/logo-mobile-blue.svg'
import LogoSmallWhite from '@/assets/svgs/smallLogoWhite.svg'
import GlobeWhite from '@/assets/svgs/globeAltwhite.svg'
import GlobeBlack from '@/assets/svgs/globeAltblack.svg'
import { getFilterParams } from '@/utils/getFilterParams'
import { amountFormatter } from '@/utils/amountFormatter'
import { COUNTRY_ID, COUNTRY_PARAM } from '@/constants/cookie'
import ClipModal from './ClipModal'
import { ExperienceType } from '@/constants/generalCarousel'
import { normalizeItem } from '@/utils/normalizeUrl'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
const languages = [
  {
    name: ENGLISH,
    code: 'en',
  },
  {
    name: SPANISH,
    code: 'es',
  },
]
const main_url = import.meta.env.VITE_MAIN_URL

const ImageWithPlaceholder = ({ src, alt, placeholder, className = '' }) => {
  const handleLoad = (e) => {
    const placeholderImg = e.target.previousSibling
    if (placeholderImg) {
      placeholderImg.classList.add('hidden')
    }
    e.target.classList.remove('hidden')
  }

  return (
    <div className={`relative ${className}`}>
      <img
        src={placeholder}
        alt="placeholder"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <img
        src={src}
        alt={alt}
        onLoad={handleLoad}
        className="absolute inset-0 w-full h-full object-cover hidden"
      />
    </div>
  )
}
const MyNavbar = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const { urlParams } = getFilterParams(params)

  const menuItems = [
    t('navbar.home'),
    t('navbar.aboutUs'),
    t('navbar.giftcards'),
  ]

  const menuLinks = ['/', 'about', 'giftcards']

  const [initialMenuState, setInitialMenuState] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const { isOpen: isClipOpen, onOpenChange: onClipOpenChange } = useDisclosure()
  const {
    hooks: { handleSignOut, getCountries },
    state: { isLoggedIn, user, countries },
    dispatch,
  } = useGotuuriContext()

  const { token, userData, countryId, locale } = useListenLocalStorage([
    'token',
    'userData',
    'locale',
    COUNTRY_ID,
  ])

  useEffect(() => {
    if (!!token && !!userData) {
      dispatch(setLoggedInAction(true))
      dispatch(setUserDataAction(JSON.parse(String(userData))))
    }
  }, [token, userData])

  useEffect(() => {
    if (typeof window != 'undefined') {
      const hasUser = !!window.localStorage.getItem('userData')
      const popUpShouldOpen = urlParams['showSignUp'] === 'true'
      if (popUpShouldOpen && searchParams.has('showSignUp')) {
        searchParams.delete('showSignUp')
        setSearchParams(searchParams)
        setIsOpen(!hasUser && popUpShouldOpen)
      }
    }
  }, [params])

  const onClose = () => {
    setIsOpen(false)
  }
  let location = useLocation().pathname

  useEffect(() => {
    !!!countries && getCountries()

    if (typeof window !== 'undefined') {
      setInitialMenuState(
        window.localStorage.getItem('mainMenuIsCollapsed') === 'true' || false
      )
    }
  }, [])

  const [mainMenuIsCollapsed, setMainMenuIsCollapsed] =
    useState(initialMenuState)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile && !mainMenuIsCollapsed) {
      setMainMenuIsCollapsed(mainMenuIsCollapsed)
    }
  }, [isMobile])

  const handleChangeLanguage = (language: string) => {
    localStorage.setItem('locale', language)
    window.dispatchEvent(new Event('storage'))
    window.location.reload()
  }

  const isNavBarWhite = Object.keys(NAVBAR_ROUTES_COLORS).some((key) => {
    if (isMobile && key === '/tuuri-register') return false
    return location.includes(key)
  })

  const isHome = location === '/'
  const myColor = isNavBarWhite || isHome ? WHITE : BLACK
  const logoSrc = isNavBarWhite || isHome ? LogoWhite : LogoBlue
  const smallLogoSrc = isNavBarWhite ? LogoSmallWhite : LogoSmallBlue
  const GlobeSrcIcon = isNavBarWhite || isHome ? GlobeWhite : GlobeBlack

  const openSignInUpModal = () => {
    track('SignIn / Up View')
    setIsOpen(true)
  }

  const renderAvatar = (src: string) => (
    <Avatar size="md" src={src} className="cursor-pointer w-12 h-12 ml-4" />
  )
  const renderBadge = (children: React.ReactNode) => (
    <Badge shape="circle" content="" color="danger">
      {children}
    </Badge>
  )
  const renderBalanceUserInfo = () => (
    <div className="flex  gap-3">
      <div className="flex justify-center items-center content-center px-2 gap-1">
        {renderBadge('')}
        <p className="w-full h-full m-0 px-2 py-1.5 flex gap-1">
          {t('balance.title')}:<b>{amountFormatter(user?.balance)}</b>
        </p>
      </div>
    </div>
  )

  const allCountries = countries?.data
    ? [
        { id: 'all', name: t('navbar.destinations'), code: 'global' },
        ...countries.data,
      ]
    : []

  const renderPopoverContent = () => (
    <PopoverContent className="w-48 py-4">
      <Select
        items={allCountries}
        defaultSelectedKeys={countryId ? [countryId as any] : ['all']}
        label={t('profile.country')}
        className="max-w-xs"
        disallowEmptySelection={true}
      >
        {(item: any) => (
          <SelectItem
            key={item.id}
            onClick={() => {
              onSelectionCountryChange(item.id)
            }}
            textValue={
              item.id === 'all'
                ? item.name
                : t(getCountryLocaleString(item.iso_code))
            }
          >
            <div className="flex flex-row gap-2 items-center">
              <ImageWithPlaceholder
                src={`/countries/${item.iso_code}.svg`}
                alt="Globe icon svg"
                className={`h-4 w-4 cursor-pointer ${item.id === 'all' ? 'black-filter' : ''}`}
                placeholder={GlobeWhite}
              />
              {item.id === 'all'
                ? item.name
                : t(getCountryLocaleString(item.iso_code))}
            </div>
          </SelectItem>
        )}
      </Select>
      <Divider className=" mb-2 bg-gray-100 " />
      <Select
        items={languages || []}
        defaultSelectedKeys={locale ? [locale as any] : undefined}
        label={t('profile.language')}
        className="max-w-xs"
      >
        {(item: any) => (
          <SelectItem
            key={item.code}
            className="text-base hover:!text-gotuuriCyan"
            role="button"
            onClick={() => {
              handleChangeLanguage(item.code)
            }}
          >
            {t(`common.${item.name}`)}
          </SelectItem>
        )}
      </Select>
    </PopoverContent>
  )

  const CountrySelect = () => {
    return (
      <NavbarMenuItem className="ml-1" key={'localeSelection-4'}>
        <Dropdown>
          <DropdownTrigger>
            <Button
              className="flex flex-row justify-start"
              variant="flat"
              disableAnimation
            >
              <p className="text-xl leading-8 font-normal text-black -ml-4">
                {t('profile.country')}
              </p>
              <img src={ArrowDown} alt="icon Button" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            {allCountries.map((item, index) => (
              <DropdownItem
                key={index}
                role="button"
                onClick={() => {
                  setIsMenuOpen(false)
                  onSelectionCountryChange(item.id)
                }}
              >
                <div className="flex flex-row gap-2 items-center">
                  <ImageWithPlaceholder
                    src={`/countries/${item.iso_code}.svg`}
                    alt="Globe icon svg"
                    className={`h-4 w-4 cursor-pointer ${item.id === 'all' ? 'black-filter' : ''}`}
                    placeholder={GlobeWhite}
                  />
                  {item.id === 'all'
                    ? item.name
                    : t(getCountryLocaleString(item.iso_code))}
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </NavbarMenuItem>
    )
  }

  const onSelectionCountryChange = async (countryId: string) => {
    updateCountryStorage(countryId)
    const locationPathname = window.location.pathname

    if (location.includes('/country/')) {
      updateLocation(countryId)
    } else {
      searchParams.has(COUNTRY_PARAM)
        ? searchParams.set(COUNTRY_PARAM, countryId)
        : searchParams.append(COUNTRY_PARAM, countryId)

      countryId === 'all' && searchParams.delete('country')
      window.history.replaceState(
        {},
        '',
        `${locationPathname}?${searchParams.toString()}`
      )

      window.location.reload()
    }
  }

  const updateCountryStorage = (countryId: string) => {
    if (countryId === 'all') {
      localStorage.removeItem(COUNTRY_ID)
    } else {
      localStorage.setItem(COUNTRY_ID, countryId)
    }
  }

  const updateLocation = (countryId: string) => {
    if (countryId === 'all') {
      navigate('/')
    } else {
      const country = countries?.data?.find(
        (country: { id: string; name: string }) => country.id === countryId
      )

      navigate(
        normalizeItem({
          name: country?.name,
          id: country?.id,
          type: ExperienceType.COUNTRY,
        })
      )
    }
  }

  return (
    <div className={`${!isMobile ? 'layout-padding' : ''}`}>
      <Navbar
        className={`!justify-between [&>header]:md:!px-0 [&>header]:pt-3 ${
          isMenuOpen ? '[&>header]:!bg-white' : ''
        }`}
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
        position="static"
        isBlurred={false}
        disableAnimation
        maxWidth="full"
      >
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className={`sm:hidden scale-150 px-8 font-black -ml-2 ${
            isNavBarWhite && !isMenuOpen ? 'text-white' : ''
          }`}
        />
        {isMobile && user?.balance > 0 && renderBadge('')}
        <NavbarBrand className="justify-end md:justify-start">
          <Link to="/" aria-label="Go to home page">
            <img
              src={logoSrc}
              alt="Go home gotuuri logo"
              className="hidden md:flex w-40"
              width="160"
              height={'34.84'}
            />
            <div className="!ml-auto">
              <img
                src={smallLogoSrc}
                alt="Go home gotuuri small logo"
                width={44}
                height={39.11}
                className="flex md:hidden w-11 h-auto"
              />
            </div>
          </Link>
        </NavbarBrand>
        <NavbarContent className="hidden md:flex items-center " justify="end">
          <NavbarItem>
            <Link
              to="/about"
              role="link"
              aria-label={`Go to ${t('navbar.aboutUs')}`}
              onClick={() => track('Nav Bar Click', { screen: 'About Us' })}
            >
              <span className={`text-base text-${myColor}`}>
                {t('navbar.aboutUs')}
              </span>
            </Link>
          </NavbarItem>

          <NavbarItem>
            <Popover placement="bottom" showArrow={true}>
              <PopoverTrigger>
                <Button
                  startContent={
                    countryId ? (
                      <ImageWithPlaceholder
                        src={`/countries/${countries?.data?.find((country) => country.id === countryId)?.iso_code}.svg`}
                        alt="Globe icon svg"
                        className="h-4 w-4 cursor-pointer"
                        placeholder={GlobeSrcIcon}
                      />
                    ) : (
                      <img
                        className="h-4 w-4 cursor-pointer"
                        src={GlobeSrcIcon}
                        alt="Globe icon svg"
                      />
                    )
                  }
                  variant="light"
                  color="default"
                  className={`text-base text-${myColor}`}
                  disableAnimation
                >
                  {countryId
                    ? `${t(getCountryLocaleString(countries?.data?.find((country) => country.id === countryId)?.iso_code))} `
                    : t('navbar.destinations')}{' '}
                  {`(${languages.find((language) => language.code === locale)?.code.toUpperCase()})`}
                </Button>
              </PopoverTrigger>
              {renderPopoverContent()}
            </Popover>
          </NavbarItem>

          <NavbarItem>
            {isLoggedIn && Object.keys(user).length ? (
              <div className="flex items-center gap-4">
                <Dropdown placement="bottom-end">
                  {user?.balance > 0 ? (
                    renderBadge(
                      <DropdownTrigger>
                        {renderAvatar(user?.avatar || DefaultAvatar)}
                      </DropdownTrigger>
                    )
                  ) : (
                    <DropdownTrigger>
                      {renderAvatar(user?.avatar || DefaultAvatar)}
                    </DropdownTrigger>
                  )}

                  <DropdownMenu aria-label="Profile Actions" variant="flat">
                    {user?.balance > 0 && (
                      <DropdownSection showDivider>
                        <DropdownItem className="hover:!text-gotuuriCyan !p-0">
                          <Link
                            to={'/profile/credit-giftcard/balance'}
                            role="link"
                            aria-label={`Go to ${t('navbar.agenda')}`}
                          >
                            {renderBalanceUserInfo()}
                          </Link>
                        </DropdownItem>
                      </DropdownSection>
                    )}

                    <DropdownSection showDivider>
                      <DropdownItem className="hover:!text-gotuuriCyan !p-0">
                        <Link
                          to="agenda"
                          role="link"
                          aria-label={`Go to ${t('navbar.agenda')}`}
                          onClick={() =>
                            track('Nav Bar Click', { screen: 'Agenda' })
                          }
                        >
                          <p className="w-full h-full m-0 px-2 py-1.5">
                            {t('navbar.agenda')}
                          </p>
                        </Link>
                      </DropdownItem>
                      <DropdownItem className="hover:!text-gotuuriCyan !p-0">
                        <Link
                          to="favorites"
                          role="link"
                          aria-label={`Go to ${t('favoritePage.title')}`}
                          onClick={() =>
                            track('Nav Bar Click', { screen: 'Favorites' })
                          }
                        >
                          <p className="w-full h-full m-0 px-2 py-1.5">
                            {t('favoritePage.title')}
                          </p>
                        </Link>
                      </DropdownItem>
                      <DropdownItem className="hover:!text-gotuuriCyan !p-0">
                        <Link
                          to="profile"
                          role="link"
                          aria-label={`Go to ${t('navbar.profile')}`}
                          onClick={() =>
                            track('Nav Bar Click', { screen: 'Profile' })
                          }
                        >
                          <p className="w-full h-full m-0 px-2 py-1.5">
                            {t('navbar.profile')}
                          </p>
                        </Link>
                      </DropdownItem>
                    </DropdownSection>
                    <DropdownItem className="hover:!text-gotuuriCyan !p-0">
                      <Link
                        to="giftcards"
                        role="link"
                        aria-label={`Go to ${t('navbar.giftcards')}`}
                        onClick={() =>
                          track('Nav Bar Click', { screen: 'Gift Cards' })
                        }
                      >
                        <p className="w-full h-full m-0 px-2 py-1.5">
                          {t('navbar.giftcards')}
                        </p>
                      </Link>
                    </DropdownItem>
                    {/* <DropdownItem className="hover:!text-gotuuriCyan !p-0 px-3">
                      <Link
                        to="#"
                        role="link"
                        aria-label={`Go to ${t('navbar.giftcards')}`}
                        onClick={handleCopyClick}
                      >
                        <p className="w-full h-full m-0 px-2 py-1.5">
                          {t('navbar.referAndWins')}
                        </p>
                      </Link>
                    </DropdownItem> */}
                    <DropdownItem
                      className="hover:!text-rose-400"
                      onClick={() => handleSignOut()}
                    >
                      {t('navbar.signOut')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => openSignInUpModal()}
                  variant="light"
                  className={`hidden md:inline-flex px-4 py-1.5 rounded-full border text-${myColor} border-${myColor} ml-3 w-28 h-10`}
                >
                  {t('navbar.signIn')}
                </Button>
                <Button
                  onClick={() => openSignInUpModal()}
                  className="hidden md:inline-flex px-4 py-1.5 !bg-gotuuriCyan rounded-full text-white !ml-3 w-28 h-10"
                >
                  {t('navbar.signUp')}
                </Button>
              </>
            )}
          </NavbarItem>
        </NavbarContent>
        <NavbarMenu>
          {isLoggedIn && !!user ? (
            <>
              {menuItems.map((item, index) => (
                <NavbarMenuItem
                  className="ml-1 first:mt-12 mb-2 text-xl leading-8 font-normal text-black"
                  key={`${item}-${index}`}
                  onClick={() => {
                    setIsMenuOpen(false)
                    track('Nav Bar Click', {
                      screen:
                        index === 0
                          ? 'Home'
                          : index === 1
                            ? 'About Us'
                            : index === 2
                              ? 'Contact'
                              : 'Gift Cards',
                    })
                  }}
                >
                  <Link
                    color={
                      index === 2
                        ? 'primary'
                        : index === menuItems.length - 1
                          ? 'danger'
                          : 'foreground'
                    }
                    className="w-full"
                    to={menuLinks[index]}
                  >
                    {item}
                  </Link>
                </NavbarMenuItem>
              ))}
              <Divider className=" mb-4 bg-gray-100 " />
              {user?.balance > 0 && (
                <NavbarMenuItem
                  className="ml-1 mb-2 text-xl leading-8  text-black"
                  key={`profile-0`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Link
                    className="w-full"
                    to={'/profile/credit-giftcard/balance'}
                    role="link"
                  >
                    {renderBalanceUserInfo()}
                  </Link>
                </NavbarMenuItem>
              )}

              <NavbarMenuItem
                className="ml-1 mb-2 text-xl leading-8  text-black"
                key={`profile-1`}
                onClick={() => setIsMenuOpen(false)}
              >
                <Link
                  className="w-full"
                  to={'profile'}
                  role="link"
                  onClick={() => track('Nav Bar Click', { screen: 'Profile' })}
                >
                  <p>{t('navbar.profile')}</p>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem
                className="ml-1 mb-2 text-xl leading-8 text-black"
                key={`agenda-0`}
                onClick={() => setIsMenuOpen(false)}
              >
                <Link
                  className="w-full"
                  to={'agenda'}
                  role="link"
                  onClick={() => track('Nav Bar Click', { screen: 'Agenda' })}
                >
                  <p>{t('navbar.agenda')}</p>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem
                className="ml-1 mb-4 text-xl leading-8 text-black"
                key={`favorites-0`}
                onClick={() => setIsMenuOpen(false)}
              >
                <Link
                  className="w-full"
                  to={'favorites'}
                  role="link"
                  onClick={() =>
                    track('Nav Bar Click', { screen: 'Favorites' })
                  }
                >
                  <p>{t('favoritePage.title')}</p>
                </Link>
              </NavbarMenuItem>
              <Divider className=" mt-2 mb-4 bg-gray-100 " />
              <CountrySelect />
              <NavbarMenuItem className="ml-1" key={'localeSelection-4'}>
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      className="flex flex-row justify-start"
                      variant="flat"
                      disableAnimation
                    >
                      <p className="text-xl leading-8 font-normal text-black -ml-4">
                        {t('navbar.language')}
                      </p>
                      <img src={ArrowDown} alt="icon Button" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Static Actions">
                    {languages.map((language, index) => (
                      <DropdownItem
                        key={index}
                        className="hover:cursor-pointer hover:text-gotuuriCyan  text-base"
                        role="button"
                        onClick={() => {
                          handleChangeLanguage(language.code)
                        }}
                      >
                        {t(`common.${language.name}`)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </NavbarMenuItem>
              <NavbarMenuItem
                className="!mx-auto text-xl leading-8 font-thin bg-black text-white rounded-3xl mt-24 mb-8 px-6 md:px-0 py-1"
                key={`signOut-0`}
              >
                <Button
                  onClick={() => {
                    handleSignOut()
                    setIsMenuOpen(false)
                  }}
                  type="button"
                  className="inline-flex md:hidden text-white font-thin text-lg h-6"
                  variant="light"
                >
                  {t('navbar.signOut')}
                </Button>
              </NavbarMenuItem>
            </>
          ) : (
            <>
              {menuItems.map((item, index) => (
                <NavbarMenuItem
                  className="ml-1 first:mt-20 mb-2 text-xl leading-8 font-normal text-black"
                  key={`${item}-${index}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Link
                    color={
                      index === 2
                        ? 'primary'
                        : index === menuItems.length - 1
                          ? 'danger'
                          : 'foreground'
                    }
                    className="w-full"
                    to={menuLinks[index]}
                  >
                    {item}
                  </Link>
                </NavbarMenuItem>
              ))}
              <NavbarMenuItem
                className="ml-1 mb-2 text-xl leading-8 font-bold  text-black"
                key={`download-2`}
                onClick={() => setIsMenuOpen(false)}
              >
                <a href={LINK_APP} target="_blank">
                  {t('navbar.download')}
                </a>
              </NavbarMenuItem>
              <Divider className=" mb-2 bg-gray-100 " />
              <CountrySelect />
              <NavbarMenuItem className="ml-1" key={'localeSelection-4'}>
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      className="flex flex-row justify-start"
                      variant="flat"
                      disableAnimation
                    >
                      <p className="text-xl leading-8 font-normal text-black -ml-4">
                        {t('navbar.language')}
                      </p>
                      <img src={ArrowDown} alt="icon Button" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Static Actions">
                    {languages.map((language, index) => (
                      <DropdownItem
                        key={index}
                        className="hover:cursor-pointer hover:text-gotuuriCyan  text-base"
                        role="button"
                        onClick={() => {
                          handleChangeLanguage(language.code)
                        }}
                      >
                        {t(`common.${language.name}`)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </NavbarMenuItem>

              <NavbarMenuItem
                className="!mx-auto text-xl leading-8 font-thin bg-black text-white rounded-3xl mt-32 px-6 md:px-0 py-1"
                key={`download-3`}
              >
                <Button
                  onClick={() => {
                    openSignInUpModal()
                    setIsMenuOpen(false)
                  }}
                  type="button"
                  className="inline-flex md:hidden text-white font-thin text-lg h-6"
                >
                  {t('navbar.signInUp')}
                </Button>
              </NavbarMenuItem>
            </>
          )}
        </NavbarMenu>
      </Navbar>
      {isOpen && <SignInUp open={isOpen} onClose={onClose} />}

      {
        <ClipModal
          isOpen={isClipOpen}
          onOpenChange={onClipOpenChange}
          url={`${main_url}?ref=${user?.id}`}
        />
      }
    </div>
  )
}

export default MyNavbar
