import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Snippet,
} from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

const ClipModal = ({ isOpen, onOpenChange, url }) => {
  const { t } = useTranslation()
  return (
    <div onLoad={() => window.scrollTo(0, 0)}>
      <Modal size="lg" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                
              </ModalHeader>
              <ModalBody className="mb-10">
                <h1 className='text-center text-2xl font-semibold'>{t('navbar.referAndWinsDescription')}</h1>
                <Snippet
                  symbol=""
                  variant="solid"
                  color="default"
                  tooltipProps={{
                    color: 'foreground',
                    content: t('navbar.copyLink'),
                    disableAnimation: true,
                    placement: 'top',
                    closeDelay: 0,
                  }}
                >
                  {url}
                </Snippet>
                <p className="">
                {/* {t('navbar.referAndWinsDescription')} */}
                </p>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ClipModal
