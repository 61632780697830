import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotFoundExperiencePage = () => {
  const { t } = useTranslation()

  return (
    <div className="font-['Questrial'] mt-12 pb-12 px-8">
      <div className="sm:flex-row md:flex-row lg:max-xl:flex-row">
        <div className="flex flex-col items-center justify-center mt-24">
          <h1 className="grid font-bold text-5xl mb-3">{t('notFound.oops')}</h1>
          <h2 className="text-2xl text-center mb-3">{t('notFound.sorry')}</h2>
          <p
            className="text-[#5d5d5d] text-center"
            dangerouslySetInnerHTML={{ __html: t('notFound.message') }}
          ></p>
          <p className="text-gotuuriCyan text-center">
            <Link to="/">{t('notFound.homepage')}</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFoundExperiencePage
