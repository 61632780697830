import { useTranslation } from 'react-i18next'
import { Skeleton } from '@nextui-org/react'

const LayoutExperienceSkeleton = () => {
  const { t } = useTranslation()

  const sliderClass = 'h-64 md:h-[36vh] lg:h-[52vh] overflow-hidden'
  return (
    <div className="w-full layout-padding">
      <div className="relative">
        <div className="mt-12 mb-3 space-y-3">
          <Skeleton className="h-10 w-9/12" />
          <Skeleton className="h-10 w-8/12 block md:hidden" />
        </div>
      </div>

      <div className="flex gap-4 xl:gap-8">
        <section className="w-full md:w-8/12 lg:w-4/6">
          <div className={`flex flex-row ${sliderClass} gap-2 mb-12`}>
            <div className="hidden md:flex flex-col [&>*]:w-full [&>*]:rounded-2xl w-1/5 gap-4">
              <Skeleton className="md:h-[11vh] lg:h-[12vh]" />
              <Skeleton className="md:h-[11vh] lg:h-[12vh]" />
              <Skeleton className="md:h-[11vh] lg:h-[12vh]" />
              <Skeleton className="md:h-[11vh] lg:h-[12vh]" />
            </div>
            <Skeleton className={`w-full md:w-4/5 rounded-2xl`} />
          </div>

          <div className="[&>*]:h-10 space-y-4 h-[216px]">
            <Skeleton className="w-full" />
            <Skeleton className="w-11/12" />
            <Skeleton className="w-5/6" />
          </div>

          <h3 className="text-2xl mb-6 mt-8">{t('experience.includeTitle')}</h3>
          <div className="[&>*]:w-full [&>*]:p-4 [&>*]:mx-2 [&>*]:rounded-2xl flex h-56 w-11/12 md:w-full mx-auto">
            <Skeleton />
            <Skeleton />
            <Skeleton className="hidden md:block" />
            <Skeleton className="hidden 2xl:block" />
          </div>
        </section>
        <Skeleton className="flex-1 md:h-[26vh] lg:h-[42vh] hidden md:block w-1/4 rounded-2xl"></Skeleton>
      </div>
    </div>
  )
}

export default LayoutExperienceSkeleton
