import { useState, useEffect } from 'react'
import { Modal } from 'components/Modal'
import { CheckEmailModal } from 'components/SignInUp/CheckEmailModal'
import { SignInModal } from 'components/SignInUp/SignInModal'
import { SignUpModal } from 'components/SignInUp/SignUpModal'
import { RecoverPasswordModal } from 'components/SignInUp/RecoverPasswordModal'
import { SignUpSuccessModal } from 'components/SignInUp/SignUpSuccessModal'

const enum StepsToSignup {
  checkEmail = 'checkEmail',
  signIn = 'signIn',
  signUp = 'signUp',
  recoverPassword = 'recoverPassword',
  signUpSuccess = 'signUpSuccess',
}

const SignUpSteps = {
  checkEmail: (props) => <CheckEmailModal {...props} />,
  signIn: (props) => <SignInModal {...props} />,
  signUp: (props) => <SignUpModal {...props} />,
  recoverPassword: (props) => <RecoverPasswordModal {...props} />,
  signUpSuccess: (props) => <SignUpSuccessModal {...props} />,
}
interface User {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  password?: string
}

const user = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
}

const SignInUp = ({ open, onClose }) => {

  const [signUpStep, setSignUpStep] = useState(StepsToSignup.checkEmail)
  const [stepProps, setStepProps] = useState()

  const [myUser, setMyUser] = useState<User>(user)

  const handlecheckEmail = (params) => {
    setSignUpStep(StepsToSignup.checkEmail)
  }

  const handleSignUp = (params) => {
    setSignUpStep(StepsToSignup.signUp)
    setMyUser({ ...myUser, email: params.email })
  }

  const handleSignIn = (params) => {
    setSignUpStep(StepsToSignup.signIn)
    setMyUser({ ...myUser, email: params.email })
  }

  const handleRecoverPassword = (params) => {
    setSignUpStep(StepsToSignup.recoverPassword)
    setMyUser({ ...myUser, email: params.email })
  }

  const handleSignUpSuccess = () => {
    setSignUpStep(StepsToSignup.signUpSuccess)
  }

  useEffect(() => {
    let props = null

    switch (signUpStep) {
      case StepsToSignup.checkEmail:
        props = {
          handleSignUp,
          handleSignIn,
          onClose,
        }
        setStepProps(props)
        break
      case StepsToSignup.signUp:
        props = {
          user: myUser,
          email: myUser.email,
          handlecheckEmail,
          handleSignUpSuccess,
        }
        setStepProps(props)
        break

      case StepsToSignup.signIn:
        props = {
          onClose,
          user: myUser,
          email: myUser.email,
          handlecheckEmail,
          handleRecoverPassword,
        }
        setStepProps(props)
        break

      case StepsToSignup.recoverPassword:
        props = {
          email: myUser.email,
          handleSignIn,
        }
        setStepProps(props)
        break

      case StepsToSignup.signUpSuccess:
        props = {
          onClose,
        }
        setStepProps(props)
        break

      default:
        break
    }
  }, [signUpStep])

  return (
    <div onLoad={()=>window.scrollTo(0,0)}>
      <Modal isOpen={open} onClose={onClose}>
        {SignUpSteps[signUpStep](stepProps)}
      </Modal>
    </div>
  )
}

export default SignInUp
