import genericAdultSvg from 'assets/svgs/genericAdult.svg'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { Accordion } from 'components/Accordion'
import { Button, Divider, Tooltip } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import lessButtonIcon from 'assets/svgs/lessButton.svg'
import addButtonIcon from 'assets/svgs/addButton.svg'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { googleTrack } from '@/utils/analytics'

interface totalParticipants {
  adults: number
  children: number
  babies: number
  seniors: number
}
export const INITIAL_PARTICIPANTS = {
  adults: 1,
  children: 0,
  babies: 0,
  seniors: 0,
}
const ParticipantsRow = ({ selectedHourIndex, handleTotalParticipants }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const {
    state: { availableHours, experience, bookingData },
  } = useGotuuriContext()

  const [totalParticipants, setTotalParticipants] =
    useState<totalParticipants>(INITIAL_PARTICIPANTS)

  useEffect(() => {
    if (!!bookingData && bookingData.experienceId === id) {
      setTotalParticipants(
        bookingData?.totalParticipants ?? INITIAL_PARTICIPANTS
      )
    }
  }, [bookingData])

  const [availablePlaces, setAvailablePlaces] = useState(0)

  useEffect(() => {
    const localTotalParticipant =
      !!bookingData &&
      bookingData?.bookingHour === availableHours?.[selectedHourIndex]?.hour
        ? (bookingData.totalParticipants as typeof totalParticipants)
        : INITIAL_PARTICIPANTS

    const countParticipants = Object.values(localTotalParticipant).reduce(
      (sum, value) => sum + value,
      0
    )

    if (countParticipants > 1) {
      setAvailablePlaces(
        (availableHours?.[selectedHourIndex]?.available_places ??
          experience?.max_persons) -
          countParticipants +
          1
      )
    } else {
      setAvailablePlaces(
        availableHours?.[selectedHourIndex]?.available_places ??
          experience?.max_persons
      )
    }

    setTotalParticipants(localTotalParticipant)
    handleTotalParticipants(localTotalParticipant)
  }, [availableHours, selectedHourIndex])

  const updateParticipants = (participantType, value) => {
    const newParticipants = {
      ...totalParticipants,
      [participantType]: Math.max(
        0,
        totalParticipants[participantType] + value
      ),
    }
    setTotalParticipants(newParticipants)
    handleTotalParticipants(newParticipants)
    setAvailablePlaces(availablePlaces + -1 * value)
  }
  const ParticipantCounter = ({ title, subtitle, participantType }) => {
    let countParticipants = 0
    Object.entries(totalParticipants)
      .filter(([key, _]) => key !== participantType)
      .forEach(([key, value]) => {
        countParticipants += value
      })

    const canRemove =
      (countParticipants == 0 && totalParticipants[participantType] > 1) ||
      (countParticipants > 0 && totalParticipants[participantType] > 0)
    const canAdd = availablePlaces > 1

    return (
      <>
        <div className="flex flex-row items-center pl-px py-2 select-none">
          <div className="flex flex-col">
            <p className="font-semibold">{title}</p>
            <p className="text-darkGray text-xs">{subtitle}</p>
          </div>
          <Tooltip
            //se pone null porque si lo condiciono solo, cuando es 0 la condicion se cumple
            isDisabled={selectedHourIndex != null}
            content={t('bookingCard.firstSelectHour')}
          >
            <div className="flex flex-row ml-auto items-center ">
              <Button
                isIconOnly
                className={`w-6 h-6 min-w-unit-0 rounded-full ${
                  canRemove ? '' : 'opacity-50'
                } min-w-6 data-[hover=true]:bg-white`}
                variant="light"
                onClick={() => {
                  if (canRemove) {
                    updateParticipants(participantType, -1)
                  }
                }}
              >
                <img
                  className="w-full h-full"
                  src={lessButtonIcon}
                  alt="less button icon svg"
                />
              </Button>
              <h3 className="w-6 lg:w-8 mx-auto text-center">
                {totalParticipants[participantType]}
              </h3>
              <Button
                isIconOnly
                className={`w-6 h-6 min-w-unit-0 rounded-full ${
                  canAdd ? '' : 'opacity-50'
                } min-w-6 data-[hover=true]:bg-white`}
                variant="light"
                onClick={() => {
                  if (canAdd) {
                    updateParticipants(participantType, 1)
                  }
                }}
              >
                <img
                  className="w-full h-full"
                  src={addButtonIcon}
                  alt="less button icon svg"
                />
              </Button>
            </div>
          </Tooltip>
        </div>
      </>
    )
  }
  return (
    <>
      <Accordion
        header={(isOpen) => (
          <div
            role="button"
            onClick={() => googleTrack('ExperienceParticipantSelect')}
            className="flex flex-row w-full select-none"
          >
            <img
              className="h-5 w-5 mr-4 my-auto"
              src={genericAdultSvg}
              alt="calendar svg"
            />
            <h3 className="text-base text-black text-center truncate">
              {t('bookingCard.participants')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        <ParticipantCounter
          title={t('bookingCard.adults')}
          subtitle={t('bookingCard.age13More')}
          participantType={'adults'}
        />
        {(experience?.is_package && experience?.include_children) ||
        (!experience?.is_package && !!experience?.price_children) ? (
          <ParticipantCounter
            title={t('bookingCard.children')}
            subtitle={t('bookingCard.age2to12')}
            participantType={'children'}
          />
        ) : null}
        {(experience?.is_package && experience?.include_babies) ||
        (!experience?.is_package && !!experience?.price_babies) ? (
          <ParticipantCounter
            title={t('bookingCard.babies')}
            subtitle={t('bookingCard.ageLess2')}
            participantType={'babies'}
          />
        ) : null}
        {(experience?.is_package && experience?.include_seniors) ||
        (!experience?.is_package && !!experience?.price_seniors) ? (
          <ParticipantCounter
            title={t('bookingCard.seniors')}
            subtitle={t('bookingCard.age65More')}
            participantType={'seniors'}
          />
        ) : null}
      </Accordion>
      <Divider className="mb-8 mt-2" />
    </>
  )
}
export default ParticipantsRow
