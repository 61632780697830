export const EXPERIENCE_TYPE = {
  true: 'experience.details.private',
  false: 'experience.details.shared',
}
export const EXPERIENCE_SCHEDULE = {
  true: 'experience.details.private',
  false: 'experience.details.shared',
}

export const EXPERIENCE_LANG = {
  is_spanish: 'experience.details.spanish',
  is_english: 'experience.details.english',
  is_german: 'experience.details.german',
  is_french: 'experience.details.french',
  is_portuguese: 'experience.details.portuguese',
}
export const EXPERIENCE_DIFFICULTY = {
  0: 'experience.difficultyLevels.notApply',
  1: 'experience.difficultyLevels.easy',
  2: 'experience.difficultyLevels.medium',
  3: 'experience.difficultyLevels.hard',
  4: 'experience.difficultyLevels.extreme',
}

export const EXPERIENCE_POLICY = {
  1: 'experience.cancelationPoliciesValues.noRefundable',
  2: 'experience.cancelationPoliciesValues.refundable24',
  3: 'experience.cancelationPoliciesValues.refundable48',
  4: 'experience.cancelationPoliciesValues.refundable72',
  5: 'experience.cancelationPoliciesValues.refundable7',
  6: 'experience.cancelationPoliciesValues.refundable15',
}

export const EXPERIENCE_CANCELLATION_POLICY = {
  1: 'bookingCard.cancelationPoliciesValues.noRefundable',
  2: 'bookingCard.cancelationPoliciesValues.refundable24',
  3: 'bookingCard.cancelationPoliciesValues.refundable48',
  4: 'bookingCard.cancelationPoliciesValues.refundable72',
  5: 'bookingCard.cancelationPoliciesValues.refundable7',
  6: 'bookingCard.cancelationPoliciesValues.refundable15',
}

export const EXPERIENCE_DETAIL_TYPES = {
  not_included: 'not_included',
  included: 'included',
  requirements: 'requirements',
  recommendations: 'recommendations',
}

export const EXPERIENCE_DETAIL_OPTIONS: {
  id: keyof typeof EXPERIENCE_DETAIL_TYPES
  name: string
}[] = [
  {
    id: 'included',
    name: 'Incluye',
  },
  {
    id: 'not_included',
    name: 'No incluye',
  },
  {
    id: 'requirements',
    name: 'Requerimientos',
  },
  {
    id: 'recommendations',
    name: 'Recomendaciones',
  },
]
