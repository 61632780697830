import { GotuuriState } from '@/models/gotuuri-state'
import { get } from '@/services/axios/axios'
import { ActionType } from '@/store/actions/base-action'
import { SET_HIGHLIGHTS } from 'store/actions'

export const useGetHighlights = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const getHighlights = async (countryId = null) => {
    await get({
      path: `featured-categories${countryId ? `?countryId=${countryId}` : ''}`,
      headers: { 'Cache-Control': 'public, max-age=31536000, immutable' },
    }).then((data: unknown) => {
      dispatch({ type: SET_HIGHLIGHTS, payload: data })
    })
  }

  return { getHighlights }
}
