import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Divider } from '@nextui-org/react'
import DetailSimpleBullet from './DetailSimpleBullet'

const ChubbDisclosure = () => {
  const { t } = useTranslation()

  return (
    <>
      <Divider className="bg-liteGray my-6" />
      <div className="flex flex-col md:flex-row">
        <h3 className="text-2xl mb-4 w-60 md:mb-0">
          {t('experience.chubbTitle')}
        </h3>
        <section className="flex-1">
          <ul className={`w-full flex flex-col flex-wrap overflow-hidden`}>
            <DetailSimpleBullet
              textClass="w-full"
              name={
                <>
                  {t('experience.chubb')}
                  <Link
                    to="/chubb-clients-coverage"
                    className="cursor-auto"
                    aria-label={t('experience.chubb2')}
                  >
                    {' '}
                    <span className="underline underline-offset-2 hover:text-gotuuriCyan cursor-pointer">
                      {t('experience.chubb2')}
                    </span>
                  </Link>
                </>
              }
            />
          </ul>
        </section>
      </div>
    </>
  )
}

export default ChubbDisclosure
