import { get } from '@/services/axios/axios'
import { setCarouselsAction, setLoadingCarouselsAction } from 'store/actions'
import { ActionType } from 'store/actions/base-action'
interface CarrouselProps {
  type: string
  id?: string
  countryId?: string
}
export const useGetCarousels = (dispatch: (action: ActionType) => void) => {
  const getCarouselExperiences = async ({
    type,
    countryId = null,
    id = '',
  }: CarrouselProps) => {
    dispatch(setCarouselsAction([]))
    dispatch(setLoadingCarouselsAction(true))
    await get({
      path: `carousels/${type}/${id}${countryId ? `?countryId=${countryId}` : ''}`,
      headers: {
        'user-id': JSON.parse(localStorage.getItem('userData'))?.id,
        //'Cache-Control': 'public, max-age=31536000, immutable',
      },
    })
      .then((data: any) => {
        dispatch(setCarouselsAction(data?.data || []))
        dispatch(setLoadingCarouselsAction(false))
      })
      .catch((e) => {
        dispatch(setLoadingCarouselsAction(false))
      })
  }

  return { getCarouselExperiences }
}
