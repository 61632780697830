import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CustomLeftArrow, CustomRightArrow } from '../CarouseElements'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { useNavigate } from 'react-router-dom'
import { SignInUp } from '@/components/SignInUp'
import { ExperienceCard } from '@/components/ExperienceCard'

const SimilarExperiencesCarousel = ({ title }: { title?: React.ReactNode }) => {
  const {
    hooks: { setFavoriteExperience, getFilterExperiences },
    state: { user, experiences: allExperiences, experience },
  } = useGotuuriContext()
  const firstRender = useRef(true)
  const countRender = useRef(0)

  const getExperiences = (searchBy: 'simple' | 'advanced') => {
    if (countRender.current > 2) return
    if (!experience) return

    firstRender.current = false
    countRender.current++
    if (searchBy == 'simple') {
      getFilterExperiences({
        page: 1,
        newSearch: true,
        data: {
          city: experience?.city?.id,
        },
      })
    }
    if (searchBy == 'advanced') {
      let rangePrice

      if (experience.price_package) {
        rangePrice = [experience?.price_package, experience?.price_package * 2]
      } else {
        rangePrice = [experience?.price_adults, experience?.price_adults * 2]
      }

      getFilterExperiences({
        page: 1,
        newSearch: true,
        data: {
          country: experience?.country?.id,
          categories:
            experience?.categories?.map((category) => category.id) || [],
          rangePrice,
        },
      })
    }
  }
  let experiences = allExperiences?.data.filter(
    (item) => item.id != experience?.id
  )

  useEffect(() => {
    getExperiences('advanced')
  }, [experience])

  useEffect(() => {
    if (firstRender.current) return
    if (experiences?.length < 4) {
      getExperiences('simple')
    }
  }, [experiences])

  const { t } = useTranslation()

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const addFavorite = (e, experience) => {
    e.stopPropagation()
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'carousel',
        })
      : setIsOpen(true)
  }

  if (!experiences) {
    return <></>
  }
  return (
    <>
      {title && title}
      <Carousel
        className="static -mr-[11px] md:-mr-[9px] md:ml-1"
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 1540 },
            items: 4,
          },
          desktop: {
            breakpoint: { max: 1540, min: 1280 },
            items: 4,
          },
          tablet: {
            breakpoint: { max: 1280, min: 768 },
            items: 3,
          },
          mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2,
          },
        }}
        infinite={true}
        ssr={true}
        focusOnSelect={false}
        removeArrowOnDeviceType={['mobile', 'tablet']}
        autoPlay={true}
        autoPlaySpeed={3000}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {!!experiences &&
          experiences?.map((localExperience, index) => {
            return (
              <div className="my-1 pr-2.5 md:pr-5 pl-0.5 2xl:pl-px" key={index}>
                <ExperienceCard
                  experience={localExperience}
                  navigate={navigate}
                  defaultLang={defaultLang}
                  addFavorite={addFavorite}
                  screenName={`Experience Page ${experience.name}`}
                  t={t}
                />
              </div>
            )
          })}
      </Carousel>
      {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default SimilarExperiencesCarousel
