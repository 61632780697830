import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/icons'
import { CityInterface } from '@/types/city'
import { normalizeItem } from '@/utils/normalizeUrl'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../Loading'

const CarouselDestinations = ({
  data,
  isMobile,
  className,
  eventTrigger,
}: {
  data: CityInterface[]
  isMobile?: boolean
  className?: string
  eventTrigger?: (item) => void
}) => {
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    mode: isMobile ? 'free-snap' : 'snap',
    breakpoints: {
      '(min-width: 1px)': {
        slides: { perView: 1.5, spacing: 2 },
      },
      '(min-width: 766px)': {
        slides: { perView: 2, spacing: 3 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 3 },
      },
    },
    slides: { perView: isMobile ? 1.5 : 3, spacing: 3 },
    initial: 0,
    created() {
      setLoaded(true)
    },
  })

  useEffect(() => {
    if (!loaded && !data?.length) return
    instanceRef.current?.update()
  }, [loaded, data])

  return (
    <div className={`navigation-wrapper ${className} relative`}>
      <div ref={sliderRef} className="keen-slider">
        {!!data?.length ? (
          data?.map((item, index) => (
            <Link
              key={index}
              className="keen-slider__slide min-w-fit max-w-fit"
              to={normalizeItem({ ...(item as any), type: 'destinations' })}
              onClick={() => {
                eventTrigger(item)
                window.scrollTo(0, 0)
              }}
            >
              <div key={index} className="h-64 md:h-80 cursor-pointer relative">
                <img
                  className={`object-cover w-full h-full rounded-2xl p-2`}
                  src={item?.image}
                  alt={item?.name}
                />
                <div className="absolute bottom-8 left-8 right-4 px">
                  <h2 className="text-white text-2xl select-none">
                    {item?.name}
                  </h2>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="h-64 md:h-80 w-full">
            <Loading fullHeight={false} />
          </div>
        )}
      </div>
      <ArrowLeftIcon
        onClick={(e: any) => {
          e.stopPropagation() ||
            (instanceRef?.current && instanceRef.current?.prev())
        }}
        className="h-12 w-12 absolute -left-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
      />
      <ArrowRightIcon
        onClick={(e: any) =>
          e.stopPropagation() ||
          (instanceRef?.current && instanceRef.current?.next())
        }
        className="h-12 w-12 absolute -right-4 top-[43%] text-lightGray rounded-full shadow-md p-3.5 !bg-white z-10 cursor-pointer hidden md:block"
      />
    </div>
  )
}

export default memo(CarouselDestinations)
