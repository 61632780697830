import { HeaderBanner } from '@/components/HeaderBanner'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useCallback, useEffect, useState } from 'react'
import { BannerApps } from '@/components/BannerApps'
import { useTranslation } from 'react-i18next'
import { GeneralCarousel } from '@/components/Carousels/GeneralCarousel'
import { CategoriesCarousel } from '@/components/Carousels/CategoriesCarousel'
import { Layout } from '@/components/Layout'
import { ExperienceList } from '@/components/ExperienceList'
import { Button } from '@nextui-org/react'
import { setReferentCodeAction } from '@/store/actions'
import { track } from '@/utils/analytics'
import { Link, useLocation } from 'react-router-dom'
import { ExperienceType } from '@/constants/generalCarousel'
import { Loading } from '@/components/Loading'
import { getTopDestinationsLocaleString } from '@/utils/generalTitle.Util'
import {
  getCountryDestinationUrl,
  getCountryHightlightUrl,
} from '@/utils/normalizeUrl'
import { ExperienceCarousel } from '@/components/Carousels/ExperienceCarousel'
import {
  CarouselCategories,
  CarouselDestinations,
  CarouselHighlights,
} from '@/components/NewCarousels'
import { useIsMobile } from '@/utils/mobile'
import { FilterButton } from '@/components/FilterButton'
import { COUNTRY_ID } from '@/constants/cookie'

const MainPage = () => {
  const { t } = useTranslation()
  const {
    hooks: {
      getCities,
      getHighlights,
      getExperiences,
      setFavoriteExperience,
      setFilterData,
      getCarouselExperiences,
      getFilterExperiences,
    },
    state: {
      cities,
      country,
      highlights,
      categories,
      experiences,
      loadingExperiences,
      referentCode,
      user,
      pagination,
    },
    dispatch,
  } = useGotuuriContext()

  const { state: referent } = useLocation()

  const [currentPage, setCurrentPage] = useState(pagination?.currentPage ?? 1)
  const highlightsUrl = getCountryHightlightUrl()

  useEffect(() => {
    if (referentCode) {
      localStorage.setItem('referentCode', referentCode)
      dispatch(setReferentCodeAction(null))
    }

    getCarouselExperiences({
      type: 'home',
      countryId: localStorage.getItem(COUNTRY_ID),
    })

    getCities(
      localStorage.getItem(COUNTRY_ID) ? localStorage.getItem(COUNTRY_ID) : null
    )

    !!!highlights && getHighlights(localStorage.getItem(COUNTRY_ID))
    setFilterData(null)
    /* getExperiences({
      page: currentPage,
      newSearch: true,
    }) */
    getFilterExperiences({
      page: 1,
      data: localStorage.getItem(COUNTRY_ID)
        ? { country: localStorage.getItem(COUNTRY_ID) }
        : {},
      newSearch: true,
    })
    track('Home View', referent ? { url: referent } : null)
  }, [])

  const loadMoreExperiences = useCallback(
    (pageNumber) => {
      if (!!pagination?.currentPage && pageNumber <= pagination?.totalPage) {
        setCurrentPage(pageNumber)
        /* getExperiences({
          page: pageNumber,
          newSearch: false,
        }) */
        getFilterExperiences({
          page: pageNumber,
          data: localStorage.getItem(COUNTRY_ID)
            ? { country: localStorage.getItem(COUNTRY_ID) }
            : {},
          newSearch: false,
        })
      }
    },
    [experiences]
  )
  return (
    <>
      <HeaderBanner />
      <Layout
        title={t('header.metaTitle')}
        description={t('header.metaDescription')}
      >
        <div className="flex justify-between items-center h-20 mt-4">
          <CarouselCategories
            className="py-0 md:py-4 w-full md:w-[88%] px-0 md:pl-12"
            data={categories || []}
          />
          <div className="hidden md:block">
            <FilterButton iconOnly />
          </div>
        </div>
        {highlights?.length > 1 && (
          <>
            <div className="flex justify-between items-center mb-6 mt-8">
              <h2 className={`text-2xl md:text-4xl select-none`}>
                {t('home.highlights')}
              </h2>
              <Link to={highlightsUrl}>
                <Button className="!bg-black text-white rounded-full px-6 h-9">
                  {t('home.viewAll')}
                </Button>
              </Link>
            </div>
            <CarouselHighlights
              data={highlights}
              eventTrigger={(data) =>
                track('Highlight View', {
                  highlightName: data.name,
                  highlightId: data.id,
                  screen: 'Home',
                })
              }
            />
          </>
        )}

        <div className="flex justify-between items-center mb-6 mt-12">
          <h2 className={`hidden md:block text-2xl md:text-4xl select-none `}>
            {t(getTopDestinationsLocaleString(country?.iso_code))}
          </h2>
          <h2 className={`block md:hidden text-2xl md:text-4xl select-none`}>
            {t('home.destinations')}
          </h2>
          <Link to={getCountryDestinationUrl(country)}>
            <Button className="!bg-black text-white rounded-full px-6 h-9">
              {t('home.viewAll')}
            </Button>
          </Link>
        </div>
        <CarouselDestinations
          data={cities}
          eventTrigger={(data) =>
            track('Destination View', {
              destinationName: data.name,
              destinationId: data.id,
              screen: 'Home',
            })
          }
        />
        <ExperienceCarousel textClass="text-xl md:text-4xl" />
        {loadingExperiences ? (
          <Loading />
        ) : (
          <ExperienceList
            className="mb-16"
            experiences={experiences?.data || []}
            title={t('home.allExperiences')}
            user={user}
            setFavoriteExperience={setFavoriteExperience}
          />
        )}
        {currentPage < pagination?.totalPage ? (
          <Button
            radius="full"
            className="!bg-black text-white w-36 mx-auto flex mb-16"
            onClick={() => loadMoreExperiences(currentPage + 1)}
          >
            {t('destinationPage.viewMore')}
          </Button>
        ) : null}
      </Layout>

      {/* <BannerApps /> */}
    </>
  )
}

export default MainPage
