import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeartIcon from 'assets/svgs/bgWhiteFullHeartIcon.svg'
import EmptyHeartIcon from 'assets/svgs/bgWhiteEmptyHeartIcon.svg'
import ShareIcon from 'assets/svgs/bgWhiteShareButton.svg'
import { SignInUp } from '@/components/SignInUp'

const ExperienceShareFavorite = ({
  className = 'flex gap-1 md:gap-2',
}: {
  className?: string
}) => {
  const {
    state: { experience, user },
    hooks: { setFavoriteExperience },
  } = useGotuuriContext()
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()

  const addFavorite = () => {
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'experience',
        })
      : setIsOpen(true)
  }
  return (
    <>
      <div className={className}>
        <a
          href={`https://wa.me/?text=${(window?.location?.href).replace('&', t('experience.and'))}`}
          target="_blank"
        >
          <img
            src={ShareIcon}
            alt="share icon svg"
            className="cursor-pointer hover:scale-110 active:scale-125 h-10 w-10 rounded-full drop-shadow-sm"
          />
        </a>
        <img
          src={experience?.favorite ? FullHeartIcon : EmptyHeartIcon}
          alt="heart icon svg"
          role="button"
          className="cursor-pointer hover:scale-110 active:scale-125 h-10 w-10 rounded-full drop-shadow-sm"
          onClick={() => {
            addFavorite()
          }}
        />
      </div>
      {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default ExperienceShareFavorite
