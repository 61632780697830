import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import leftIcon from 'assets/svgs/leftIcon.svg'
import rightIcon from 'assets/svgs/rightIcon.svg'
import { ExperienceShareFavorite } from '@/pages/ExperiencePage'

interface ExperiencePhotosCarouselProps {
  photos: string[]
  experience: any
}

const ExperiencePhotosCarousel = ({
  photos,
  experience,
}: ExperiencePhotosCarouselProps) => {
  const PhotosArray =
    photos.length < 4
      ? Array.from({ length: 4 }, (_, i) => photos[i % photos.length])
      : photos

  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let sliderRef1: any = useRef()
  let sliderRef2: any = useRef()

  useEffect(() => {
    setNav1(sliderRef1)
    setNav2(sliderRef2)
  }, [])

  const customArrowClass =
    '!p-2  z-10 !bg-black rounded-full  !size-8 opacity-70'

  const sliderClass = 'h-[412px] md:h-[36vh] lg:h-[52vh] overflow-hidden'

  const CustomLeftArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <img
        onClick={onClick}
        className={`${className} ${customArrowClass} !left-4 !pl-1.5`}
        style={{ ...style, display: 'block' }}
        src={leftIcon}
        alt="Left icon svg"
      />
    )
  }

  const CustomRightArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <img
        onClick={onClick}
        className={`${className} ${customArrowClass} !right-4 !pr-1.5`}
        style={{ ...style, display: 'block' }}
        src={rightIcon}
        alt="Right icon svg"
      />
    )
  }

  const settings = {
    arrows: false,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  }

  return (
    <>
      <PhotoProvider maskOpacity={0.5}>
        <div className="flex gap-2 relative">
          <Slider
            className={`w-1/5 !hidden md:!block ${sliderClass} -mt-0.5`}
            vertical={true}
            asNavFor={nav1}
            arrows={false}
            verticalSwiping={true}
            ref={(slider) => (sliderRef2 = slider)}
            slidesToShow={4}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {PhotosArray.map((image, index) => {
              return (
                <div
                  key={index}
                  className="md:h-[11vh] lg:h-[12.1vh] cursor-pointer"
                >
                  <img
                    className="object-cover w-full h-full rounded-xl"
                    src={image}
                    alt={`${experience?.name} image ${index}`}
                    title={`${experience?.name} image ${index}`}
                    fetchpriority="high"
                  />
                </div>
              )
            })}
          </Slider>
          <Slider
            {...settings}
            className={`w-full md:w-4/5 ${sliderClass}`}
            asNavFor={nav2}
            autoplaySpeed={4000}
            autoplay={true}
            swipeToSlide={true}
            ref={(slider) => (sliderRef1 = slider)}
          >
            {PhotosArray.map((image, index) => {
              return (
                <div
                  key={index}
                  className={`${sliderClass} cursor-pointer pb-3 md:px-2`}
                >
                  <PhotoView src={image}>
                    <img
                      className="object-cover w-full h-full md:rounded-2xl"
                      src={image}
                      alt={`${experience?.name} image ${index}`}
                      title={`${experience?.name} image ${index}`}
                      fetchpriority="high"
                    />
                  </PhotoView>
                </div>
              )
            })}
          </Slider>
          <ExperienceShareFavorite className="md:hidden flex gap-2 absolute top-4 right-4" />
        </div>
      </PhotoProvider>
    </>
  )
}

export default ExperiencePhotosCarousel
