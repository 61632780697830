import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SignInUp } from '../SignInUp'
import { ExperienceCard } from '../ExperienceCard'

interface ExperienceListProps {
  experiences: any
  title?: string
  user: any
  setFavoriteExperience: ({ experience, userId, type }) => void
  className?: string
}
const ExperienceList = ({
  experiences,
  title,
  user,
  setFavoriteExperience,
  className,
}: ExperienceListProps) => {
  const { t } = useTranslation()
  const location = useLocation().pathname.split('/')[1]

  const LocationsForClevertap = {
    destinations: 'Destination',
    categories: 'Category',
    highlights: 'Highlights',
    favorites: 'Favorites',
    search: 'Search',
    country: 'Country',
  }

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const addFavorite = (e, experience) => {
    e.preventDefault()
    e.stopPropagation()
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'experiences',
        })
      : setIsOpen(true)
  }

  return (
    <>
      {!!title ? (
        <div>
          <h2 className="text-2xl md:text-4xl mb-6 mt-12">{title}</h2>
        </div>
      ) : null}
      <div
        className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-3 md:gap-x-4 lg:gap-x-5 gap-y-8 ${className}`}
      >
        {!!experiences
          ? experiences.map((experience) => (
              <ExperienceCard
                key={experience?.id}
                experience={experience}
                screenName={LocationsForClevertap[location] || 'Home'}
                addFavorite={addFavorite}
                defaultLang={defaultLang}
                navigate={navigate}
                t={t}
              />
            ))
          : null}
      </div>
      {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}
export default ExperienceList
