import { ArrowRightIcon } from '@/assets/icons'
import { useTranslation } from 'react-i18next'

const ShowMore = ({ showAll, setShowAll }) => {
  const { t } = useTranslation()
  return (
    <span
      onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
      className="flex flex-row items-center cursor-pointer text-black font-medium w-fit ml-2"
    >
      {!showAll ? t('experience.showMore') : t('experience.showLess')}
      <ArrowRightIcon
        className={`ml-3 h-2.5 w-2.5 transition-all ${!showAll ? 'rotate-90' : 'rotate-270'}`}
      />
    </span>
  )
}

export default ShowMore
