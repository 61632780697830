import { EXPERIENCE_DETAIL_TYPES } from '@/constants/experience'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DetailSimpleBullet from './DetailSimpleBullet'
import ShowMore from './ShowMore'
import { Divider } from '@nextui-org/react'

const MAX_ITEMS = 5

const ExperienceRecommendation = () => {
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()

  const allRecomendations = experience?.details?.filter(
    ({ type }) => type === EXPERIENCE_DETAIL_TYPES.recommendations
  )

  const [showAll, setShowAll] = useState(false)

  const visibleRecomendations = showAll
    ? allRecomendations
    : allRecomendations?.slice(0, MAX_ITEMS)

  if (allRecomendations?.length === 0 || !allRecomendations) {
    return null
  }
  return (
    <>
      <Divider className="bg-liteGray my-6" />
      <div className="flex flex-col md:flex-row">
        <h3 className="text-2xl mb-4 w-full md:w-60 md:mb-0 h-min">
          {t('experience.recommendations')}
        </h3>
        <section className="flex-1">
          <ul
            className={`w-full flex flex-col ${showAll ? 'md:max-h-96' : 'md:max-h-64'} flex-wrap w-full overflow-hidden`}
          >
            {visibleRecomendations?.map((detail) => (
              <DetailSimpleBullet key={detail.id} name={detail.name} />
            ))}
          </ul>

          {allRecomendations?.length > MAX_ITEMS && (
            <ShowMore showAll={showAll} setShowAll={setShowAll} />
          )}
        </section>
      </div>
    </>
  )
}

export default ExperienceRecommendation
