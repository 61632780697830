import { Button } from '@nextui-org/react'
import { useState, useEffect } from 'react'
import { ArrowBlackImg } from '../ArrowBlackImg'

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const toggleVisibility = () => {
        if (window.scrollY > window.innerHeight) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      }

      window.addEventListener('scroll', toggleVisibility)

      return () => window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {isVisible && (
        <Button
          onClick={scrollToTop}
          isIconOnly
          radius="full"
          size="lg"
          className="hidden md:block fixed bottom-8 animate-bounce right-12 p-3 rounded-full shadow-large !bg-white hover:!bg-gray-200 hover:!opacity-100 transition-all"
          aria-label="Scroll to top"
        >
          <ArrowBlackImg className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180" />
        </Button>
      )}
    </>
  )
}
