import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface RatingProps {
  initialRating?: number
  size?: string
  disabled?: boolean
  showText?: boolean
  onRatingChange?: (rating: number) => void
  gap?: string
}

const Rating = ({
  initialRating,
  size,
  disabled,
  showText = false,
  gap,
  onRatingChange,
}: RatingProps) => {
  const [selectedRating, setSelectedRating] = useState(initialRating || 0)

  const { t } = useTranslation()

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating)
    if (onRatingChange) {
      onRatingChange(rating)
    }
  }

  const ratingOptions = [
    '',
    t('rating.veryBad'),
    t('rating.bad'),
    t('rating.regular'),
    t('rating.good'),
    t('rating.veryGood'),
  ]

  return (
    <div className={`${!disabled ? 'cursor-pointer' : ''} h-max`}>
      <div className={`flex flex-row ${gap || 'gap-1'} items-center `}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <div key={rating} className="text-center">
            <span
              className={` ${rating <= selectedRating ? 'text-[#FFBD00]' : 'text-[#b8b8b8]'}  select-none leading-3 ${
                size || 'text-4xl'
              }`}
              onClick={() => !disabled && handleRatingSelect(rating)}
            >
              {rating <= selectedRating ? `★` : `★`}
            </span>
            <p className={`text-xs mt-1 ${showText ? 'block' : 'hidden'}`}>
              {ratingOptions[rating]}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Rating
