import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import IconButtom from '@/components/IconButtom/IconButtom'
import { useTranslation } from 'react-i18next'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { normalizeItem } from '@/utils/normalizeUrl'
import { ExperienceType } from '@/constants/generalCarousel'
import { CleverEvents } from '@/utils/events'
import { track } from '@/utils/analytics'
import { Rating } from '@/components/Rating'
import ExperienceShareFavorite from './ExperienceShareFavorite'
import { scrollToElement } from '@/utils/common'

const ExperienceTitle = () => {
  const {
    state: { experience },
  } = useGotuuriContext()

  const { t } = useTranslation()

  const trackFunction = () => {
    track('Experience Location View', {
      experienceName: experience?.name,
      experienceId: experience?.id,
    })
    CleverEvents.DestinationView({
      name: experience?.destination.name,
      id: experience?.destination.id,
      screen: 'Experience',
    })
  }

  return (
    <>
      <h1
        className={`text-3xl md:text-4xl mt-4 ${!experience?.rating ? 'mb-3' : ''} md:mb-0 md:mt-12 line-clamp-4`}
      >
        {experience?.h1 || experience?.name}
      </h1>
      {!!experience?.rating && (
        <div className="flex flex-wrap items-center text-base gap-2 text-black mb-6 md:mb-0">
          {/* <span className="hidden md:block">
            {t('experience.details.customerReviews')}
          </span> */}
          <Rating size="text-xl" initialRating={experience.rating} disabled />{' '}
          {(experience?.rating || 0).toFixed(1)}
          {experience?.reviews?.length > 0 && (
            <>
              <span className="block">/ </span>
              <span
                className="underline font-semibold cursor-pointer"
                onClick={() => scrollToElement('reviews')}
              >
                {t('experience.details.customerReviewsComments', {
                  count: experience?.reviews?.length,
                })}
              </span>
            </>
          )}
        </div>
      )}
      <div className="hidden md:flex justify-between items-center mb-4 mt-3">
        <div className="flex gap-2">
          <IconButtom
            textButtom={t(
              getCountryLocaleString(experience?.country?.iso_code)
            )}
            linkUrl={normalizeItem({
              ...experience?.country,
              type: ExperienceType.COUNTRY,
            })}
            altImg={'Map pin'}
            onClickFunction={() =>
              CleverEvents.CountryView({
                id: experience?.country?.id,
                name: experience?.country?.name,
                screen: 'Experience',
              })
            }
          />
          {experience?.destination?.name && (
            <IconButtom
              onClickFunction={trackFunction}
              textButtom={experience?.destination?.name}
              linkUrl={normalizeItem({
                id: experience?.destination.id,
                name: experience?.destination.name,
                type: ExperienceType.DESTINATIONS,
              })}
              altImg={'Map pin'}
            />
          )}
        </div>
        <ExperienceShareFavorite />
      </div>
    </>
  )
}

export default ExperienceTitle
