import { useTranslation } from 'react-i18next'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { EXPERIENCE_CANCELLATION_POLICY } from '@/constants/experience'
import {
  CalendarCheckIcon,
  HeadphoneMicrophoneIcon,
  MedicalKitIcon,
} from '@/assets/icons'

const DisclosureWhyUs = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()
  const iconClass = 'w-5 h-5 flex-shrink-0'
  const pClass = 'text-base font-normal text-black ml-3'
  const divClass = 'flex items-center mb-1'

  return (
    <div
      className={`border-lightGray border-1 rounded-2xl px-4 md:px-6 py-4 ${className}`}
    >
      <p className="text-2xl mb-3">{t('bookingCard.whyUs')}</p>
      <div className={divClass}>
        <MedicalKitIcon className={iconClass} />
        <p className={pClass}>{t('bookingCard.accidentInsurance')}</p>
      </div>
      {experience?.cancellation_policy !== 1 && (
        <div className={divClass}>
          <CalendarCheckIcon className={iconClass} />
          <p className={pClass}>
            {t(EXPERIENCE_CANCELLATION_POLICY[experience?.cancellation_policy])}
          </p>
        </div>
      )}
      <div className={divClass}>
        <HeadphoneMicrophoneIcon className={iconClass} />
        <p className={pClass}>{t('bookingCard.personalizedAttention')}</p>
      </div>
    </div>
  )
}

export default DisclosureWhyUs
